import moment from "moment";
import phoneFilter from "@/helpers/filters/phoneFilter";
// import { dictionariesHelper } from "@/helpers/dictionariesHelper";

const tableColsCodes = [
  {
    text: "ID пользователя",
    value: "user",
    renderFunction: value => value.user_id,
    sort: true,
    width: "70"
  },
  {
    text: "Код",
    value: "code",
    sort: true
  },
  {
    text: "Причина",
    renderFunction: value => value.request_reason_display,
    value: "reg_status",
    sort: true
  },
  {
    text: "Статус",
    renderFunction: value => value.send_status_display,
    value: "send_status",
    sort: true
  },
  {
    text: "Дата создания",
    // value: "created_at"
    renderFunction: value =>
      `${moment(value.created_at * 1000).format("DD.MM.YYYY HH:mm")}`
  },
  {
    text: "Дата окончания",
    // value: "created_at"
    renderFunction: value =>
      `${moment(value.expires_at * 1000).format("DD.MM.YYYY HH:mm")}`
  },

  {
    text: "Пользователь",
    value: "user",
    src: "/img/table_icons/tabbar__icon_9.svg",
    width: 20,
    renderFunction: value => {
      return (
        (value?.user_detail?.full_name || "-") +
        (value.phone_number != null
          ? ", " + phoneFilter.execute(value?.phone_number)
          : "")
      );
    },
    sort: true
  },
  // {
  //   text: "Регистрация",
  //   value: "is_phone_confirmed",
  //   renderFunction: value =>
  //     `<p style='
  //       ${
  //         value?.user_detail?.is_phone_confirmed === true
  //           ? "color: #95C23D!important;"
  //           : "color: #EB5C6D!important;"
  //       }'
  //     >${
  //       dictionariesHelper.accountsStatus.userAuth[
  //         value?.user_detail?.is_phone_confirmed
  //       ]
  //     }</p>`,
  //   userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70]
  // },
  {
    text: "Дата запрета отправки кода",
    // value: "created_at"
    renderFunction: value =>
      `${moment(value.to_ban_timeout_at * 1000).format("DD.MM.YYYY HH:mm")}`
  }
];

export default tableColsCodes;
