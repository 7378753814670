<template>
  <div>
    <MainLayout>
      <template v-slot:default>
        <!-- popup подтверждающий удаление -->
        <DeletePopupAdmin
          title="Запрос"
          description="Вы уверены, что хотите удалить выбранный запрос?"
          :dialog="dialog"
          @closeDeletePopup="closeDeletePopup"
          :delete-function="deleteFunction"
        />
        <!-- /popup подтверждающий удаление-->
        <TableFilters :queries="queries">
          <template v-slot:filter-title>
            <span v-if="sourceObject.count">
              <span class="title-filter">Всего:</span>{{ sourceObject.count }}
            </span>
          </template>
        </TableFilters>
        <TableLayout
          :loading="isDateLoaded"
          disable-router
          :table-data="sourceObject.results"
          :header="header"
          :source-object="sourceObject"
          delete-button-direction-start
        >
          <template slot-scope="{ itemFather }">
            <button
              @click="clickDelete(itemFather)"
              class="form__btn-delete btn btn-delete"
              style="background-color: #eb5c6d"
            ></button>
          </template>
        </TableLayout>
      </template>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsCodes from "@/helpers/tableCols/codes";
import DeletePopupAdmin from "@/components/popup/DeletePopupCommon";

import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "CardsPage",
  data() {
    return {
      dialog: false, // диалоговое окно popup удаления
      key: null,
      loadingBtn: false,
      queries: query.codes
    };
  },
  components: { TableFilters, TableLayout, MainLayout, DeletePopupAdmin },
  computed: {
    header() {
      return tableColsCodes;
    },
    sourceObject() {
      return this.$store.getters.getPageCardsList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    }
  },
  methods: {
    clickDelete(obj) {
      this.key = obj.key;
      this.dialog = true;
    },
    closeDeletePopup(data) {
      this.dialog = data;
    },
    deleteFunction() {
      this.$store
        .dispatch("deleteCodes", { key: this.key })
        .then(() => {
          this.isDateLoaded = true;
          this.$store.dispatch("fetchPageCodesList").finally(() => {
            this.isDateLoaded = false;
          });
        })
        .then(() => {
          this.$store.dispatch("clearCheckedList");
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.codes)
          );
        });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.dispatch("clearCheckedList");
    this.$store.commit("setSearchQuery", {
      query: this.urlQuery.query
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageCodesList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("CLEAR_CHECKED_ALL");
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query
          // type: this.type
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageCodesList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store
      .dispatch("clearCardsPage")
      .then(() => {
        this.$store.dispatch("clearSortQuery");

        this.$store.dispatch("clearCheckedList");
      })
      .then(() => next());
  }
};
</script>
